import * as React from 'react';
import classnames from 'classnames';

const baseClass = 'form-element';

class FormElement extends React.Component {
  state = {
    value: '',
    active: false,
  };

  onFocus = (e) => {
    this.setState({
      active: true,
    });
  }

  onBlur = (e) => {
    this.setState({
      active: false,
    });
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  }
  
  render() {
    const { name, label, type = 'text', element = 'input' } = this.props;
    const { active, value } = this.state;

    const cls = classnames(baseClass, {
      [`${baseClass}--active`]: active,
    });

    return (
      <div className={cls}>
        <label className={`${baseClass}__label`} htmlFor={name}>
          {label}
        </label>

        {element === 'textarea' ? (
          <textarea
            className={`${baseClass}__textarea`}
            name={name}
            type={type}
            value={value}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          ></textarea>
        ) : (
          <input
            className={`${baseClass}__input`}
            name={name}
            type={type}
            value={value}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
        )}
      </div>
    );
  }
}

export default FormElement;
