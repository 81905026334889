import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import { Helmet } from 'react-helmet';

// import Navbar from './components/Navbar';
// import Routes from './routes';
import HomePage from './components/pages/HomePage';
import GoogleTestPage from './components/pages/GoogleTestPage';

// import logo from './logo.svg';
import './sass/index.scss';

const baseClass = 'app';

// const App = () => (
//   <BrowserRouter>
//     <div className={baseClass}>
//       {/* <Navbar /> */}

//       <div className={`${baseClass}__content`}>
//         <Routes />
//       </div>
//     </div>
//   </BrowserRouter>
// );

const App = () => (
  <div className={baseClass}>
    <Helmet>
      <meta name="description" content="Merit Machining Ltd. manufactures surface wellheads and sub-sea components to the specifications required by clients of the oil and gas related industry" />

      <meta property="og:url" content="https://www.meritmachining.com" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Merit Machining Ltd." />
      <meta property="og:description" content="Merit Machining Ltd. manufactures surface wellheads and sub-sea components to the specifications required by clients of the oil and gas related industry" />
      <meta property="og:image" content="https://www.meritmachining.com/merit.png" />

      <meta name="robots" content="noindex, nofollow" />
    </Helmet>

    <div className={`${baseClass}__content`}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/google-test" component={GoogleTestPage} />
          <Route path="/" component={HomePage} />
        </Switch>
      </BrowserRouter>
    </div>
  </div>
);

export default App;
