import React, { Component } from 'react';

import FormElement from '../form/FormElement';

const baseClass = 'request-quote-section';

class RequestQuoteSection extends Component {
  state = {
    stage: 0,
  };

  render() {
    const { stage } = this.state;

    return (
      <div className={baseClass} id="request-quote">
        <div className={`${baseClass}__header`}>
          request quote
        </div>
    
        <div className={`${baseClass}__body`}>
            {stage === 0 && (
              <form className={`${baseClass}__form`} method="POST" netlify="true">
                <input type="hidden" name="form-name" value="contact-quote" />
                <FormElement element="input" name="name" label="Name" />
                <FormElement element="input" name="email" type="email" label="Email" />
                <FormElement element="textarea" name="message" label="Message" />
                <div netlify-recaptcha="true"></div>

                <div className={`${baseClass}__button-container`}>
                  <button className={`${baseClass}__submit-button`} type="submit">Send</button>
                </div>
              </form>
            )}

            {stage === 1 && (
              <div className={`${baseClass}__form--submitted`}>
                <div className={`${baseClass}__form-message`}>Thanks for reaching out. We'll get back to within 24 hours.</div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default RequestQuoteSection;
