import * as React from 'react';

import Logo from './Logo';

const baseClass = 'footer';

class Footer extends React.Component {
  render() {
    return (
      <div className={baseClass}>
        <div className={`${baseClass}__content`}>
          <div className={`${baseClass}__column ${baseClass}__column--logo`}>
            <Logo className={`${baseClass}__logo`} inverted={true} />
          </div>

          <div className={`${baseClass}__column ${baseClass}__column--links`}>
            <div className={`${baseClass}__column-header`}>
              links
            </div>

            <div className={`${baseClass}__column-body`}>
              <a className={`${baseClass}__link`} href="/">home</a>
              <a className={`${baseClass}__link`} href="#about-us">about us</a>
              {/* <a className={`${baseClass}__link`} href="#services">services</a> */}
              <a className={`${baseClass}__link`} href="#equipment">equipment</a>
            </div>
          </div>

          <div className={`${baseClass}__column ${baseClass}__column--about`}>
            <div className={`${baseClass}__column-header`}>
              about
            </div>

            <div className={`${baseClass}__column-body`}>
              <div>
                <a className={`${baseClass}__link ${baseClass}__link--email`} href="mailto:sales@meritmachining.com">sales@meritmachining.com</a>
              </div>
              <div>Tel: <a className={`${baseClass}__link`} href="tel:17804981889">+1-780-498-1889</a></div>
              <div>Fax: +1-780-490-6028</div>
              <br/>

              <div>8786 - 51 Avenue</div>
              <div>Edmonton, AB</div>
              <div>T5E 5E8</div>
            </div>
          </div>

          <div className={`${baseClass}__column ${baseClass}__column--hours`}>
            <div className={`${baseClass}__column-header`}>
              hours of operation
            </div>

            <div className={`${baseClass}__column-body`}>
              <div>Monday - Friday</div>
              <div>8:00am - 5:00pm</div>
              <br />
              <div>Saturday & Sunday</div>
              <div>Closed</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
