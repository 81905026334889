import React from 'react';
import classnames from 'classnames';

import PUMA_300L from '../../images/PUMA-300L.jpg';
import PUMA_400 from '../../images/PUMA-400.jpg';
import PUMA_480L from '../../images/PUMA-480L.jpg';
import HAAS from '../../images/HAAS.jpg';
import DOOSAN_480L from '../../images/DOOSAN_480L.png';

const EQUIPMENT = [
  {
    name: 'Puma 300/L Lathe',
    quantity: 2,
    description: [
      'Capacity: Swing over bed 590/Swing over saddle 450/Max machining dia 255/Max Machining length 648',
      'Travel: X axis 282 Y axis 1330',
      'Spindle: Chuck dia 255 Speed Range 35 Nose A2 Bore 86 Bearing dia 130',
      'Turrel: No. of tool stations 10(base holder)',
      'Capacity: Maximum Diameter 14.00 inch x 8.00 inch maximum length',
    ],
    image: PUMA_300L,
  },

  {
    name: 'Puma 400/B Lathe',
    quantity: 1,
    description: [
      'Max turning Dia 550 Max Turning length 1043 Distance between centres 1135',
      'Draw tube ID 117.5 Spindle speed 20-2000 Travel X-axis 16 Y-axis 20',
      'No of Tools std 12 Turning Tool Shank size 32 Boring Bar Diameter 60',
      'Capacity: Maximum Diameter 21.50 inch x 5.00 inch maximum length',
    ],
    image: PUMA_400,
  },

  {
    name: 'Puma 480/L Lathe',
    quantity: 1,
    description: [
      'Large 650mm (25.60”) maximum turning diameter',
      '2,082mm (81.97”) maximum turning length',
      'High speed 1,500 rpm spindle',
      '12 station heavy duty turret 320mm (12.60”)',
      'Hydraulic steady rest with 1.97”-12.20” capacity',
    ],
    image: PUMA_480L,
  },

  {
    name: 'Hass CNC VF5 Milling Machine',
    quantity: 1,
    description: [
      'Vertical Machining Centre',
    ],
    image: HAAS,
  },

  {
    name: 'Doosan 480L',
    quantity: 1,
    description: [
      '12 Station Turret',
      '24" Chuck',
      '7" Spindle Bore',
      'Steady Rest',
    ],
    image: DOOSAN_480L,
  },
];

const baseClass = 'equipment-section';

const EquipmentSection = () => (
  <div className={baseClass} id="equipment">
    <div className={`${baseClass}__header`}>
      our equipment
    </div>

    <div className={`${baseClass}__body`}>
      {EQUIPMENT.map((equipment, index) => (
        <Machine
          key={equipment.name}
          baseClass={baseClass}
          machine={equipment}
          alternate={index % 2 === 1}
        />
      ))}
    </div>
  </div>
);


const Machine = ({ baseClass, machine, alternate }) => {
  const cls = classnames(`${baseClass}__machine`, {
    [`${baseClass}__machine--alternate`]: alternate,
  });

  return (
    <div className={cls} id="equipment">
      <div className={`${baseClass}__image-container`}>
        <img src={machine.image} alt={machine.name} className={`${baseClass}__image`} />
      </div>

      <div className={`${baseClass}__info`}>
        <div className={`${baseClass}__block-header`}>
          {machine.name}
        </div>

        <ul>
          {machine.description.map((line, i) => (
            <li key={i} className={`${baseClass}__description-item`}>
              {line}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EquipmentSection;
