import React from 'react';

import PRODUCT_1 from '../../images/Post-min.png';
import PRODUCT_2 from '../../images/Post2-min.png';
import PRODUCT_3 from '../../images/Post3-min.png';
import PRODUCT_4 from '../../images/Post4-min.png';
import PRODUCT_5 from '../../images/Post5-min.png';
import PRODUCT_6 from '../../images/Post6-min.png';

const baseClass = 'about-us-section';

const AboutUsSection = () => (
  <div className={baseClass} id="about-us">
    <div className={`${baseClass}__header`}>
      about us
    </div>

    <div className={`${baseClass}__body`}>
      <p>
        Merit Machining Ltd. manufactures surface wellheads and sub-sea components to the specifications required by clients from the oil and gas related industry.
        We manufacture blind flanges, thermal seals, casing hangers, adapters, flange weld necks, tubing hangers, instrumentation heads, and much more.
      </p>
      <p>
        With 20 years of experience in the trade and 10 years of company ownership, operating in this 10,700 sqft facility since 2012, David Giang and team are highly motivated to deliver precise and timely manufacturing orders.
      </p>

      <div className={`${baseClass}__products`}>
        <div className={`${baseClass}__product`}>
          <img className={`${baseClass}__product-image`} src={PRODUCT_1} alt="Product 1" />
        </div>

        <div className={`${baseClass}__product`}>
          <img className={`${baseClass}__product-image`} src={PRODUCT_2} alt="Product 2" />
        </div>

        <div className={`${baseClass}__product`}>
          <img className={`${baseClass}__product-image`} src={PRODUCT_3} alt="Product 3" />
        </div>
      </div>

      <div className={`${baseClass}__products`}>
        <div className={`${baseClass}__product`}>
          <img className={`${baseClass}__product-image`} src={PRODUCT_4} alt="Product 4" />
        </div>

        <div className={`${baseClass}__product`}>
          <img className={`${baseClass}__product-image`} src={PRODUCT_5} alt="Product 5" />
        </div>

        <div className={`${baseClass}__product`}>
          <img className={`${baseClass}__product-image`} src={PRODUCT_6} alt="Product 6" />
        </div>
      </div>
    </div>
  </div>
);

export default AboutUsSection;
