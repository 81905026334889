import * as React from 'react';

import AboutUsSection from '../sections/AboutUsSection';
import EquipmentSection from '../sections/EquipmentSection';
import RequestQuoteSection from '../sections/RequestQuoteSection';

import Footer from '../Footer';
import Navbar from '..//Navbar';

const baseClass = 'home-page';

class HomePage extends React.Component {
  renderGetInTouch() {
    return (
      <div className={`${baseClass}__section ${baseClass}__section--get-in-touch`} id="contact-us">
        <div className={`${baseClass}__section-header`}>
          get in touch
        </div>

        <div className={`${baseClass}__section-content`}>
          <div className={`${baseClass}__company-contact-info`}>
            <div className={`${baseClass}__company-contact-info-column`}>
              <a className={`${baseClass}__link`} href="mailto:sales@meritmachining.com">sales@meritmachining.com</a>
            </div>

            <hr className={`${baseClass}__divider`} />

            <div className={`${baseClass}__company-contact-info-column`}>
              <div>Tel: <a className={`${baseClass}__link`} href="tel:+17804981889">780-498-1889</a></div>
              <div>Fax: 780-490-6028</div>
            </div>

            <hr className={`${baseClass}__divider`} />

            <div className={`${baseClass}__company-contact-info-column`}>
              <div>8786 - 51 Avenue</div>
              <div>Edmonton, AB</div>
              <div>T5E 5E8</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMap() {
    return (
      <div className={`${baseClass}__map`}>
        <iframe
          title="Google Map"
          width="100%"
          frameBorder="0"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ60y2PcEYoFMROHLuRw171-c&zoom=15&key=AIzaSyDzZz6uSAzO0Bd-6uvWshNlLwraVPNL7I0"
          allowFullScreen
        />
      </div>
    );
  }

  renderHomeSection() {
    return (
      <div className={`${baseClass}__section ${baseClass}__section--home`}>
        <div className={`${baseClass}__hero`}>
          Quality.
          <br />
          Precision.
          <br />
          Machining.
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />

        <div className={baseClass}>
          {this.renderHomeSection()}
          <AboutUsSection />
          <EquipmentSection />

          {this.renderGetInTouch()}
          {this.renderMap()}

          <RequestQuoteSection />
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}

export default HomePage;