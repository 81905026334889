import * as React from 'react';
// import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import Logo from './Logo';
import MAPLE_LEAF from '../images/maple-leaf.png';

const baseClass = 'navbar';

class Navbar extends React.Component {
  state = {
    mobileOpen: false,
  };

  constructor(props) {
    super(props);

    this.node = React.createRef();
  }

  componentDidMount() {
    // Handle clicks outside of navbar & close/toggle navbar mobile open
    document.addEventListener('click', this.closeMobileOpen, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeMobileOpen);
  }

  closeMobileOpen = (event) => {
    if (!this.node.current.contains(event.target)) {
      this.setState({ mobileOpen: false });
    }
  }

  toggleMobileOpen = () => {
    this.setState(prevState => ({
      mobileOpen: !prevState.mobileOpen,
    }));
  }

  render () {
    const { mobileOpen } = this.state;
    const cls = classnames(baseClass, {
      [`${baseClass}--open`]: mobileOpen,
    });

    return (
      <nav className={cls} ref={this.node}>
        <div className={`${baseClass}__logo`} onClick={this.toggleMobileOpen}>
          <Logo />
          <div className={`${baseClass}__logo-company-name`}>
            Merit Machining Ltd.
          </div>
          <img className={`${baseClass}__logo-maple-leaf`} src={MAPLE_LEAF} alt="Maple Leaf" />
        </div>

        <div className={`${baseClass}__nav-list`}>
          <a href="/" className={`${baseClass}__nav-link ${baseClass}__nav-link--home`} onClick={this.toggleMobileOpen}>Home</a>
          <a href="#about-us" className={`${baseClass}__nav-link`} onClick={this.toggleMobileOpen}>About Us</a>
          <a href="#equipment" className={`${baseClass}__nav-link`} onClick={this.toggleMobileOpen}>Equipment</a>
          <a href="#contact-us" className={`${baseClass}__nav-link`} onClick={this.toggleMobileOpen}>Contact Us</a>
          {/* <NavLink to="/services" className={`${baseClass}__nav-link`}>Services</NavLink> */}
          {/* <NavLink to="/careers" className={`${baseClass}__nav-link`}>Careers</NavLink> */}
        </div>
      </nav>
    );
  }
}

export default Navbar;
