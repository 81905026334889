import * as React from 'react';
import { Helmet } from 'react-helmet';

import Navbar from '..//Navbar';

const baseClass = 'home-page';

class GoogleTestPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Google Test!</title>
          <meta name="description" content="My Merit Machining Helmet Test" />
          <meta name="robots" content="index, follow" />

          <meta property="og:title" content="Merit Google" />
          <meta property="og:description" content="og Google Test" />
        </Helmet>

        <Navbar />

        <div className={baseClass} style={{ padding: '48px' }}>
          <h1 style={{ color: '#FFF' }}>Hello There...</h1>
          <h4 style={{ color: '#FFF' }}>Sorry, this page does not exist.</h4>
        </div>
      </React.Fragment>
    );
  }
}

export default GoogleTestPage;